import React from 'react'
import "./Program.css"
import img1 from "../assets/program1.jpeg"
import img2 from "../assets/program2.jpeg"
import img3 from "../assets/program3.jpeg"
export default function Programs() {
	return (
		<div className="program_section" id='programs'>
			<div className="grid-container">
				<div className="item1"><div className="program_services"><h1>Programs</h1></div></div>
				<div className="item2">
					<div className="big_card">
						<div className="icon"><img src={img1} alt="" /></div>
						<div className="heading fs-5">Cultural Cadence</div>
						<div className="description">Cultural Cadence is not just a dance tour; it's a vibrant exchange of traditions, knowledge, and artistic expressions. As we travel from city to city, our troupe of talented dancers, choreographers, and cultural ambassadors weave together a tapestry of movement, music, and stories that embody the essence of different cultures.</div>
					</div>
				</div>
				<div className="item3">
					<div className="small_card">
						<div className="icon"><img src={img2} alt="" /></div>
						<div className="heading fs-5">The Fusion of Cultures</div>
						<div className="description">Our tour is a testament to the power of cultural fusion. We blend the elegance of classical dance forms with the vigor of contemporary styles and the raw energy of street dance. Each performance is a kaleidoscope of influences, where you'll witness the harmonious coexistence of traditions from every corner of the globe.</div>
					</div></div>
				<div className="item4">
					<div className="small_card">
						<div className="icon"><img src={img3} alt="" /></div>
						<div className="heading fs-5">Educational and Inspirational</div>
						<div className="description">Cultural Cadence is not just about the performances; it's also an educational experience. In each city we visit, we conduct workshops, masterclasses, and interactive sessions to share our knowledge and learn from local talents. Our goal is to foster a deeper understanding of the diverse dance forms that make up our world.</div>
					</div></div>

				<div className="item5 ">
					<div className="small_card">
						<div className="icon"><img src={img1} alt="" /></div>
						<div className="heading fs-5">*Bringing the World to Your City:*</div>
						<div className="description">With Cultural Cadence, you don't need to travel the world to experience its cultures. We bring the world to your city, inviting you to join us on a captivating journey of discovery. Our performances are a window to distant lands, a celebration of traditions, and a reminder that we are all connected through the language of dance.</div>
					</div>
				</div>

			</div>
			{/* <div className="item5">
				<div className="small_card"> */}
					{/* <div className="icon"><img src={img2} alt="" /></div> */}
					{/* <div className="heading fs-5"></div>
					<div className="description">With Cultural Cadence, you don't need to travel the world to experience its cultures. We bring the world to your city, inviting you to join us on a captivating journey of discovery. Our performances are a window to distant lands, a celebration of traditions, and a reminder that we are all connected through the language of dance.</div>
				</div>
			</div> */}

			
		</div>


	)
}
