import React, { useState, useEffect } from 'react';
import "./Founder.css";
import img from "../assets/founderImg.jpeg";

export default function Founder() {
    const [showFullContent, setShowFullContent] = useState(false);
    const [isPhoneScreen, setIsPhoneScreen] = useState(false);

    useEffect(() => {
        function handleResize() {
            setIsPhoneScreen(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial screen size
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLoadMore = () => {
        setShowFullContent(true);
    };

    return (
        <div className="founder_section">
            <div className="heading_services"><h1>Founder</h1></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 founder-image ">
                        <img src={img} alt="Founder Image" />
                    </div>
                    <div className="col-lg-8 col-sm-12 founder-info">
                        <h4>Founder:- Vishal Khatri</h4>
                        <p>
                            {showFullContent || !isPhoneScreen ? (
                                <>
                                    <p>
                                        I'm a self-educated dancer and choreographer with a rich tapestry of experience that spans a decade in the world of dance. My journey into the art of movement began with a strong foundation in jazz, ballet, and contemporary dance. Over the years, I've honed my skills in these classic forms, allowing me to master the elegance of ballet, the expressiveness of contemporary, and the spirited rhythm of jazz.
                                    </p>
                                    <p>
                                        But my love for dance doesn't stop at these traditional styles. I am a versatile artist who thrives on the fusion of different dance forms. Hip-hop and Bollywood, in particular, have found a special place in my repertoire. These diverse influences have enriched my choreography and performance, allowing me to explore a wide spectrum of movements and storytelling techniques.
                                    </p>
                                    <p>
                                        My career has been illuminated by a series of remarkable experiences. I've had the privilege of working with notable TV celebrities, where I've contributed my artistic expertise to create visually stunning and emotionally resonant performances. Collaborating with these well-known figures has allowed me to showcase my versatility as a choreographer and has enriched my portfolio with a diverse range of projects.
                                    </p>
                                    <p>
                                        As a dancer and choreographer, my mission is to keep pushing the boundaries of my art, embracing the fusion of styles, and telling captivating stories through movement. I look forward to sharing my love for dance and the magic of choreography with the world, one step at a time.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        I'm a self-educated dancer and choreographer with a rich tapestry of experience that spans a decade in the world of dance.
                                    </p>
                                    {isPhoneScreen && (
                                        <button className='rounded founderbutton'  onClick={handleLoadMore}>Read More</button>
                                    )}
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
