import React from 'react'
import slide1 from "../assets/slide1.jpeg"
import slide2 from "../assets/slide2.jpeg"
import slide3 from "../assets/slide3.jpeg"
import slide4 from "../assets/slide4.jpeg"
import slide5 from "../assets/slide5.jpeg"
import slide6 from "../assets/slide6.jpeg"
import './Home.css'

export default function Home() {
	return (
		<div>
			<section className='home_container'>
			<div
				id="carouselExampleControls"
				className="carousel slide"
				data-ride="carousel"
			>
				<div className="carousel-inner">
				<div className="carousel-item active">
						<img className="d-block w-100" src={slide1} alt="Third slide" />
					</div>
					<div className="carousel-item ">
						<img className="d-block w-100" src={slide2} alt="First slide" />
					</div>
					<div className="carousel-item">
						<img className="d-block w-100" src={slide3} alt="Second slide" />
					</div>
					<div className="carousel-item">
						<img className="d-block w-100" src={slide4} alt="Third slide" />
					</div>
					
					<div className="carousel-item">
						<img className="d-block w-100" src={slide5} alt="Third slide" />
					</div>
					<div className="carousel-item">
						<img className="d-block w-100" src={slide6} alt="Third slide" />
					</div>
				</div>
				<a
					className="carousel-control-prev"
					href="#carouselExampleControls"
					role="button"
					data-slide="prev"
				>
					<span className="carousel-control-prev-icon" aria-hidden="true" />
					<span className="sr-only">Previous</span>
				</a>
				<a
					className="carousel-control-next"
					href="#carouselExampleControls"
					role="button"
					data-slide="next"
				>
					<span className="carousel-control-next-icon" aria-hidden="true" />
					<span className="sr-only">Next</span>
				</a>
			</div>

			</section>



			
			
		</div>
	)
}
