import React from 'react'
import './Footer.css'
export default function Footer() {
  return (

	<footer>
  <div className="footer-container" id='contact'>
    <div className="footer-section">
      <h3>About Us</h3>
      <p>
      We are a passionate and dedicated group of dancers, choreographers, and artists who have come together to celebrate the transformative power of dance
      </p>
    </div>
    {/* <div className="footer-section">
      <h3>Quick Links</h3>
      <ul>
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#">About Us</a>
        </li>
        <li>
          <a href="#">Services</a>
        </li>
        <li>
          <a href="#">Contact</a>
        </li>
      </ul>
    </div> */}
    <div className="footer-section">
      <h3>Contact Us</h3>
      {/* <p>123 Street Name, City, Country</p> */}
      <p>Email: <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=VpCqJTChLmFpGGcXTCVJZNMbtLmJssfZnfgnHKwWhWNvkgdBBMXHNLMVxthmXMRjzZkDkhg">artinditeindia@gmail.com</a></p>
      <p>Phone: +91 9799694403<br/>8005616309</p>
      <p>Instagram:<a href="https://www.instagram.com/artinditeindia?igsh=YjR1a2g4MWR1Znlh">artinditeindia</a></p>
    </div>
  </div>
  <div className="copyright">
    <p>© 2023 Artinditeindia. All rights reserved.</p>
  </div>
</footer>

  )
}
