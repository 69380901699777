import React from 'react'
import "./Gallery1.css"
import w1 from "../assets/w1.jpeg"
import w2 from "../assets/w2.jpeg"
import w3 from "../assets/w3.jpeg"
import w4 from "../assets/w4.jpeg"
import w5 from "../assets/w5.jpeg"
import w6 from "../assets/w6.jpeg"
import w7 from "../assets/w7.jpeg"
import w8 from "../assets/w8.jpeg"
const Gallery1 = () => {
    return (
        <>
            <div id='gallery'>
                <h1 className='text-center'> Weddings</h1>
                <div className="container  ">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w1} alt="" />

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w2} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w3} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3 " >
                                <div class="">
                                    <img src={w4} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w4} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w6} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w7} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w8} alt="" />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-6">
                            <div class="card my-3" >
                                <div class="">
                                    <img src={w2} alt="" />

                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <div className='d-flex flex-column justify-content-center align-items-center p-2 my-4 bg-grey'style={{ backgroundColor: "rgb(253 235 249 / 45%)"}}>
                <h2>Show Reels</h2>
                <iframe width="500" height="300" src="https://www.youtube.com/embed/fRoGscvBUNA?si=eAQ7aasdRcdIIt4C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

        </>
    )
}

export default Gallery1
