import React, { useState } from 'react'
import background from "../assets/aboutbg.jpeg"
import './About.css'
import img from "../assets/pngegg.png"

export default function About() {
	const [showFullDescription, setShowFullDescription] = useState(false);
	const paragraph = `<p>Welcome to <span className='text-color'>Art Indite India</span>, where movement becomes art and rhythm transforms into emotion. We are a passionate and dedicated group of dancers, choreographers, and artists who have come together to celebrate the transformative power of dance. Our journey is a story of dedication, creativity, and the unyielding belief that "Everything about dance is about us." we believe that dance is not just a physical expression; it's a language that transcends boundaries, cultures, and backgrounds. Established with a profound love for the art of dance, our company has grown into a vibrant community of individuals who share a common passion for movement, music, and storytelling. "Everything about dance is about us" is not just a tagline, but a testament to our core philosophy. We are committed to creating a space where everyone can come together, irrespective of their dance experience, and explore the beauty and joy of movement. Dance is about you, about me, about us, and we invite you to be a part of this inclusive journey.</p>`;
	const words = paragraph.split(' ');
	const truncatedDescription = words.slice(0, 50).join(' ') + '...';
	const handleReadMore = () => {
		setShowFullDescription(true);
	};

	const handleModalClose = () => {
		setShowFullDescription(false);
	};
	const CustomModal = ({ content, onClose }) => {
		return (
			<div className="custom-modal-overlay">
				<div className="custom-modal">
					<span className="close" onClick={onClose}>&times;</span>
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			</div>
		);
	};
	return (
		<div className='about_container bg-orange p-2 ' id='about' style={{
			backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
			textAlign: 'center'
		}}>
			<h2 className='px-2 bh'>About Us</h2>

			<div className="about_main_container">
				{/* <div className='img_about'><img src={img} alt="" /></div> */}
				<div className="about_content px-2">
					<div className="about_detail_container">
						<div>
							<div dangerouslySetInnerHTML={{ __html: showFullDescription ? truncatedDescription : truncatedDescription }} />

							{!showFullDescription && (
								<button type="button" className="button_custom" onClick={handleReadMore}>
									Read more
								</button>
							)}

							{showFullDescription && (
								<CustomModal content={paragraph} onClose={handleModalClose} />
							)}
						</div>
						{/* <p>Welcome to <span className='text-color'>Art Indite India</span>, where movement becomes art and rhythm transforms into emotion. We are a passionate and dedicated group of dancers, choreographers, and artists who have come together to celebrate the transformative power of dance. Our journey is a story of dedication, creativity, and the unyielding belief that "Everything about dance is about us." we believe that dance is not just a physical expression; it's a language that transcends boundaries, cultures, and backgrounds. Established with a profound love for the art of dance, our company has grown into a vibrant community of individuals who share a common passion for movement, music, and storytelling. "Everything about dance is about us" is not just a tagline, but a testament to our core philosophy. We are committed to creating a space where everyone can come together, irrespective of their dance experience, and explore the beauty and joy of movement. Dance is about you, about me, about us, and we invite you to be a part of this inclusive journey.</p>` */}
					</div>
				</div>
				
			</div>

		</div>
	)
}
