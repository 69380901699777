
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import './Navbar.css'
import img from "../assets/menu.png";
// import logo from '../assets/logo.jpeg'
import Programs from './Programs'
import Services from './Services'
import About from './About'
import Gallery1 from './Gallery1'
import Founder from './Founder'
import Footer from './Footer'
import Home from "./Home";
export default function Header() {


	const [navbarBg, setNavbarBg] = useState("transparent");
	  useEffect(() => {
	    window.addEventListener("scroll", handleScroll);
	    return () => {
	      window.removeEventListener("scroll", handleScroll);
	    };
	  }, []);
	  const handleScroll = () => {
	    if (window.scrollY > 100) {
	      setNavbarBg("rgb(246 233 211 / 99%)");
	    } else {
	      setNavbarBg("transparent");
	    }
	  };
	return (
		<div>
			<nav className="navbar navbar-expand-lg"
				style={{ position: 'fixed', backgroundColor: navbarBg }}>
				<div className="main_logo text-center">
					<h4 className="fs-2">ART INDITE INDIA</h4>
					<span ><p style={{ fontSize: "12px", lineHeight: "23px" }}>Concept choreography & Direction</p></span>
				</div>



				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"><img src={img} alt="" /></span>
				</button>

				<div className="collapse navbar-collapse navbarListItems" id="navbarSupportedContent">
					<ul className="navbar-nav ">
						<li className="nav-item active">
							<a className="nav-link " href="/">Home </a>
						</li>
						<li className="nav-item active">
							<a className="nav-link " href="#programs">Programs</a>
						</li>
						<li className="nav-item active">
							<a className="nav-link " href="#services">Services</a>
						</li>

						<li className="nav-item active">
							<a className="nav-link " href="#gallery">Gallery</a>
						</li>
						<li className="nav-item active">
							<a className="nav-link " href="#about">About Us</a>

						</li>
						<li className="nav-item active">
							<a className="nav-link " href="#contact">Contact</a>

						</li>
					</ul>
				</div>
			</nav>

			<Home />
			<About />
			<Services />
			<Programs />
			<Gallery1 />
			<Founder />
			<Footer />
		</div>
	)
}
