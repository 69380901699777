import React from 'react'
import img from "../assets/bg1.jpg"
import "./Services.css"

import Wedding from "../assets/wedding.jpeg";
import Shoots from "../assets/shoots.jpeg";
import Toure from "../assets/tour.jpeg";
import WorkShops from "../assets/workshop.jpeg";
import Shows from "../assets/performance.jpeg";
import Contracts from "../assets/programs.jpeg";


export default function Services() {
	return (
		<>
		<div className="service_section py-3" id='services'>
			<div className="heading_services fw-10"><h1>Services</h1></div>
			<div className="card-container">

				<div className="card_box ">
					<div className="icon "><img className="d-block w-100" src={Wedding} alt="Wedding" /></div>
					<div className="heading ">Wedding</div>
					{/* <div className="description">This is a short description for Card 1.</div> */}
				</div>

				<div className="card_box">
					<div className="icon"><img className="d-block w-100" src={Shoots} alt="Shoots" /></div>
					<div className="heading">Shoots</div>
					{/* <div className="description">This is a short description for Card 2.</div> */}
				</div>

				<div className="card_box">
					<div className="icon"><img className="d-block w-100" src={Toure} alt="Toure" /></div>
					<div className="heading">Tour</div>
					{/* <div className="description">This is a short description for Card 3.</div> */}
				</div>
				<div className="card_box">
					<div className="icon"><img className="d-block w-100" src={WorkShops} alt="Work Shops" /></div>
					<div className="heading">Work Shops</div>
					{/* <div className="description">This is a short description for Card 1.</div> */}
				</div>

				<div className="card_box">
					<div className="icon"><img className="d-block w-100" src={Shows} alt="Shows" /></div>
					<div className="heading">Shows</div>
					{/* <div className="description">This is a short description for Card 2.</div> */}
				</div>

				<div className="card_box">
					<div className="icon"><img className="d-block w-100" src={Contracts} alt="Contracts" /></div>
					<div className="heading">Contracts</div>
					{/* <div className="description">This is a short description for Card 3.</div> */}
				</div>

			</div>
			

		</div>
		
		
		</>
		

		
		// </div>
	)
}
