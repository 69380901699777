import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from "./components/About";
import Footer from "./components/Footer";
import Founder from "./components/Founder";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Programs from "./components/Programs";
import Services from "./components/Services";
import Gallery1 from "./components/Gallery1";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/gallery" element={<Gallery1 />} />
          
          <Route path="/founder" element={<Founder />} />
          <Route path="/footer" element={<Footer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
